export default {
  namespaced: true,
  state: {
    token: null,
    account: {},
  },
  mutations: {
    SET_TOKEN: function(state, value) {
      state.token = value;
    },
    SET_ACCOUNT: function(state, value) {
      state.account = value;
    },
  },
  actions: {
    set_token: (context, value) => {
      context.commit('SET_TOKEN', value);
    },
    set_account: (context, value) => {
      context.commit('SET_ACCOUNT', value);
    },
  },
}
<template>
  <div class="page-dashboard">
    <div class="columns is-gapless is-vh">
      <div class="column is-2 has-background-dark">
        <Sidebar />
      </div>
      <div class="column">
        <div class="section">
          <h1 class="title">Welcome {{ userName }}</h1>
          <div class="box">
            <div class="buttons">
              <b-button tag="router-link" to="/dashboard" type="is-link">
                Dashboard
              </b-button>
              <b-button tag="router-link" to="/quizzes" type="is-link">
                Quizzes
              </b-button>
              <b-button tag="router-link" to="/forms" type="is-link">
                Forms
              </b-button>
              <b-button tag="router-link" to="/answers" type="is-link">
                Answers
              </b-button>
              <b-button tag="router-link" to="/accounts" type="is-link">
                Accounts
              </b-button>
            </div>
          </div>
        </div>
        <div class="section">
          <form class="v-components" @submit.prevent="submit">
            <b-field grouped>
              <b-field
                label="Facebook Cover Image (jpg)"
                :type="{ 'is-danger': errors.has('coverImage') }"
                :message="errors.first('coverImage')"
              >
                <b-field v-show="!formValues.coverImage.info" class="file">
                  <b-upload
                    type="file"
                    v-model="formValues.coverImage.file"
                    name="coverImage"
                    data-vv-as="Ads"
                    v-validate="!formValues.coverImage.info ? 'required' : ''"
                  >
                    <a class="button is-primary">
                      <b-icon icon="upload"></b-icon>
                      <span>Click to upload</span>
                    </a>
                  </b-upload>
                </b-field>
              </b-field>

              <b-field>
                <b-taglist v-if="formValues.coverImage.info">
                  <b-tag
                    type="is-info"
                    closable
                    size="is-medium"
                    @close="removeCoverImage"
                  >
                    Remove | {{ formValues.coverImage.info.file.name }}
                  </b-tag>
                </b-taglist>
              </b-field>

              <b-loading :active="formValues.coverImage.loading"></b-loading>
            </b-field>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "Dashboard",
  components: {
    Sidebar,
  },
  data: function () {
    return {
      formData: {
        coverImage: null,
      },
      formValues: {
        coverImage: {
          file: null,
          loading: false,
        },
      },
    };
  },
  watch: {
    "formValues.coverImage.file": {
      handler: function (image) {
        if (image) {
          this.uploadCoverImage(image);
        }
      },
    },
  },
  computed: {
    userName: function () {
      return (
        this.$store.state.User.account.name ||
        this.$store.state.User.account.email
      );
    },
  },
  methods: {
    removeCoverImage: function () {
      this.formValues.coverImage.info = null;
      this.formValues.coverImage.file = null;
      this.formData.coverImage = null;
    },
    uploadCoverImage: async function (image) {
      const thisCompo = this;

      const response = await this.$api
        .fbImage({
          file: image,
          type: "image",
        })
        .catch((err) => {
          if (err.response) {
            thisCompo.$buefy.toast.open({
              message: err.response.data.message,
              type: "is-danger",
              position: "is-top",
            });
          } else {
            thisCompo.$buefy.toast.open({
              message: "Connection error",
              type: "is-danger",
              position: "is-bottom",
            });
          }
        });

      if (response) {
        thisCompo.$buefy.toast.open({
          message: "Successfully upload",
          type: "is-success",
          position: "is-top",
        });

        this.formData.coverImage = response.data._id;

        this.formValues.coverImage.info = response.data;
      }
    },
    submit: function () {},
  },
};
</script>

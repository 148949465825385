<template>
  <div class='component reconnect-wrapper'>
    <slot v-if="connection"></slot>

    <section v-if="connection == false" class="hero is-fullheight is-warning is-bold">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <article v-if="loading == false" class="card">
              <div class="card-content">
                <b-button type="is-info" @click.prevent="reconnect()">
                  Reconnect
                </b-button>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>

    <b-loading :is-full-page="true" :active.sync="loading"></b-loading>
  </div>
</template>

<script>
  export default {
    name: 'ReconnectWrapper',
    data: function() {
      return {
        loading: false,
        loggedIn: false,
        ignore: false,
        hasError: false,
      }
    },
    created: function() {
      if (this.$route.meta.auth) {
        this.reconnect()
      }
      else {
        this.ignore = true
      }
    },
    methods: {
      reconnect: async function() {
        const thisCompo = this

        this.hasError = false
        this.loading = true

        const [ account ] = await Promise.all([
          // re-login
            this.$api.accountInfo().catch( (err) => {
              if (err.response) {
                thisCompo.$buefy.toast.open({
                  message: 'Account expired',
                  type: 'is-danger',
                  position: 'is-bottom'
                })

                thisCompo.$store.dispatch('User/set_account', null)
                thisCompo.$store.dispatch('User/set_token', null)

                thisCompo.$router.push({name: 'login'})
                thisCompo.$appStorage.set('redirect', {name: 'login'})

                thisCompo.ignore = true
              }
              else {
                thisCompo.$buefy.toast.open({
                  message: 'Connection error',
                  type: 'is-danger',
                  position: 'is-bottom'
                })
              }

              thisCompo.hasError = true
            }),
        ])

        if (this.hasError) return

        if (account.data) {
          this.$store.dispatch('User/set_account', account.data)

          this.$buefy.toast.open({
            message: 'Successfully logged in',
            type: 'is-success',
            position: 'is-top'
          })

          this.loggedIn = true
        }

        this.loading = false
      },
    },
    watch: {
      hasError: function(newVal) {
        if (newVal) {
          this.loading = false
        }
      },
    },
    computed: {
      connection: function() {
        if (this.ignore) {
          return true
        }

        if (this.loggedIn) {
          return true
        }

        return false
      },
    },
  }
</script>
import Vue from 'vue'
import Router from 'vue-router'
import Store from './store.js'

import Dashboard from './views/Dashboard.vue'
import Logout from './views/Logout.vue'
import Login from './views/Login.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active-trail',
  linkExactActiveClass: 'is-active',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        redirectAuthenticated: 'dashboard',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },


    {
      path: '/',
      name: 'root',
      redirect: '/dashboard',
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        auth: true,
      },
    },


    {
      path: '/accounts',
      name: 'accounts',
      meta: {
        auth: true,
      },
      component: () => import('@/views/Accounts.vue')
    },
    {
      path: '/accounts/:id/update',
      name: 'accountsUpdate',
      meta: {
        auth: true,
      },
      component: () => import('@/views/AccountsUpdate.vue')
    },


    {
      path: '/quizzes',
      name: 'quizzes',
      meta: {
        auth: true,
      },
      component: () => import('@/views/Quizzes.vue')
    },
    {
      path: '/quizzes/create',
      name: 'quizzesCreate',
      meta: {
        auth: true,
      },
      component: () => import('@/views/QuizzesCreate.vue')
    },
    {
      path: '/quizzes/:id/update',
      name: 'quizzesUpdate',
      meta: {
        auth: true,
      },
      component: () => import('@/views/QuizzesUpdate.vue')
    },


    {
      path: '/forms',
      name: 'forms',
      meta: {
        auth: true,
      },
      component: () => import('@/views/Forms.vue')
    },
    {
      path: '/forms/create',
      name: 'formsCreate',
      meta: {
        auth: true,
      },
      component: () => import('@/views/FormsCreate.vue')
    },
    {
      path: '/forms/:id/update',
      name: 'formsUpdate',
      meta: {
        auth: true,
      },
      component: () => import('@/views/FormsUpdate.vue')
    },
    {
      path: '/forms/:id/analysis',
      name: 'formsAnalysis',
      meta: {
        auth: true,
      },
      component: () => import('@/views/FormsAnalysis.vue')
    },


    {
      path: '/answers',
      name: 'answers',
      meta: {
        auth: true,
      },
      component: () => import('@/views/Answers.vue')
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !Store.state.User.token) {
    next('/login');
  }
  else if(Store.state.User.token && to.meta.redirectAuthenticated && to.meta.redirectAuthenticated !== to.name) {
    next({name: to.meta.redirectAuthenticated});
  }
  else {
    next();
  }
})

export default router

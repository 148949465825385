<template>
  <section class="section page-login hero is-fullheight is-info is-bold">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="wrapper">
            <article class="card">
              <header class="card-header">
                <div class="card-header-title is-centered">
                  Login
                </div>
              </header>

              <div class="card-content">
                <LoginForm />
              </div>
            </article>

            <div class="level">
              <div class="level-item is-centered">
                <router-link class="has-text-white" to="/password-request">Forgot password</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .level {
    margin-top: 15px;
  }
</style>

<script>
import LoginForm from '@/components/forms/LoginForm.vue'

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import User from '@/stores/User.js'

import configs from '@/configs.js'

Vue.use(Vuex)
// localStorage.removeItem('vuex');
const vuexLocal = new VuexPersistence({
  modules: ['User'],
})

export default new Vuex.Store({
  state: {
    api: configs.api.url,
  },
  modules: {
    User: User,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [vuexLocal.plugin],
})

<template>
  <form class="v-components login-form" @submit.prevent="submit">
    <b-field
      label="Email"
      :type="{'is-danger': errors.has('email')}"
      :message="errors.first('email')">

      <b-input
        v-model="email"
        name="email"
        v-validate="'required|email'"
        type="email"
        placeholder="you@company.com">
      </b-input>
    </b-field>

    <b-field
      label="Password"
      :type="{'is-danger': errors.has('password')}"
      :message="errors.first('password')">

      <b-input
        v-model="password"
        name="password"
        v-validate="'required'"
        type="password">
      </b-input>
    </b-field>

    <b-button
      class="is-fullwidth"
      type="is-info"
      tag="input"
      native-type="submit"
      value="Login">
    </b-button>

    <b-loading :is-full-page="true" :active.sync="loading"></b-loading>
  </form>
</template>

<script>
export default {
  name: 'LoginForm',
  data: function() {
    return {
      loading: false,
      email: null,
      password: null,
    }
  },
  methods: {
    logIn: function() {
      const thisCompo = this

      this.loading = true

      this.$api.login({
        username: this.email,
        password: this.password,
      }).then((response) => {
        const isAdmin = response.data.account.roles.find(rid => rid == 'moderator');

        if (response.data.account.status == 1 && isAdmin) {
          thisCompo.$store.dispatch('User/set_token', 'Token ' + response.data.authToken)
          thisCompo.$store.dispatch('User/set_account', response.data.account)

          thisCompo.$axios.defaults.headers.common['Authorization'] = thisCompo.$store.state.User.token

          thisCompo.$router.push({name: 'dashboard'})
          thisCompo.$appStorage.set('redirect', {name: 'dashboard'})

          thisCompo.$buefy.toast.open({
            message: 'Successfully logged in',
            type: 'is-success',
            position: 'is-top'
          })

          thisCompo.loading = false
        }
        else if (response.data.account.status == 2) {
          thisCompo.$buefy.toast.open({
            message: 'Account is blocked',
            type: 'is-danger',
            position: 'is-top'
          })

          thisCompo.loading = false
        }
        else if (response.data.account.status == 3) {
          thisCompo.$buefy.toast.open({
            message: 'Account needs verification',
            type: 'is-warning',
            position: 'is-top'
          })

          thisCompo.loading = false
        }
        else if (!isAdmin) {
          thisCompo.$buefy.toast.open({
            message: "You're not authorized",
            type: 'is-danger',
            position: 'is-top'
          })

          thisCompo.loading = false
        }
      }).catch( (err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: 'Account not found',
            type: 'is-danger',
            position: 'is-top'
          })
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Connection error',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }

        thisCompo.loading = false
      })
    },
    submit: function() {
      const thisCompo = this

      this.$validator.validateAll().then(result => {
        if (result) {
          thisCompo.logIn()
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Form is not valid! Please check the fields.',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
      })
    }
  },
}
</script>

<template>
  <ReconnectWrapper id='app'>
    <router-view/>
  </ReconnectWrapper>
</template>

<script>
import ReconnectWrapper from '@/components/ReconnectWrapper.vue'

export default {
  components: {
    ReconnectWrapper,
  },
  watch: {
    '$store.state.User.token': function(newVal) {
      this.$axios.defaults.headers.common['Authorization'] = newVal;
    },
  },
  created: function() {
    const thisCompo = this

    this.$axios.defaults.baseURL = this.$store.state.api;

    if (this.$store.state.User.token) {
      this.$axios.defaults.headers.common['Authorization'] = this.$store.state.User.token;
    }

    this.$appStorage.on('redirect', (value) => {
      thisCompo.$appStorage.remove('redirect')

      if (value) {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const port = window.location.port;

        const redirect = new URL('http://localhost');

        redirect.protocol = protocol
        redirect.hostname = hostname
        redirect.port = port
        redirect.pathname = thisCompo.$router.resolve(value).href

        window.location = redirect.href
      }
    })
  },
}
</script>

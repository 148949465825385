<template>
  <section class="section page-login hero is-fullheight is-light is-bold">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <b-loading :active.sync="loading"></b-loading>

          <b-notification
            v-if="success"
            type="is-success"
            :closable="false"
            role="alert">
            Successfully logged out.
          </b-notification>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Logout',
  data: function() {
    return {
      loading: false,
      success: false,
    }
  },
  methods: {
    logout: function() {
      const thisCompo = this

      this.$store.dispatch('User/set_account', null)
      this.$store.dispatch('User/set_token', null)

      // this.loading = true

      thisCompo.success = true
      // thisCompo.loading = false

      thisCompo.$appStorage.set('redirect', {name: 'login'})

      setTimeout(() => {
        thisCompo.$router.push({name: 'login'})
      }, 2500)
    },
  },
  mounted: function() {
    this.logout()
  },
}
</script>

import Vue from 'vue'

const login = credentials => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/login',
    auth: credentials
  })
}

const accountInfo = () => {
  return Vue.prototype.$axios({
    method: 'get',
    url: '/admin/account/profile-self'
  })
}

const accountsList = data => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/admin/account/list',
    data: data
  })
}

const accountsUpdate = data => {
  return Vue.prototype.$axios({
    method: 'patch',
    url: '/admin/account/update',
    data: data
  })
}

const quizzesUpdate = data => {
  return Vue.prototype.$axios({
    method: 'patch',
    url: '/admin/quiz/update',
    data: data
  })
}

const quizzCreate = data => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/admin/quiz/create',
    data: data
  })
}

const quizzesList = data => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/admin/quiz/list',
    data: data
  })
}

const formsList = data => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/admin/form/list',
    data: data
  })
}

const formCreate = data => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/admin/form/create',
    data: data
  })
}

const formsUpdate = data => {
  return Vue.prototype.$axios({
    method: 'patch',
    url: '/admin/form/update',
    data: data
  })
}

const answerAnalysis = data => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/admin/answer/analysis',
    data: data
  })
}

const answersList = data => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/admin/answer/list',
    data: data
  })
}

const fbImage = data => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/admin/file/fb-image',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data,
    transformRequest: [
      data => {
        const formData = Vue.prototype.$obj2FormData(data)

        return formData
      }
    ]
  })
}

const fileUpload = data => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/admin/file/upload',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data,
    transformRequest: [
      data => {
        const formData = Vue.prototype.$obj2FormData(data)

        return formData
      }
    ]
  })
}

export default {
  login: login,

  accountInfo: accountInfo,

  accountsList: accountsList,
  accountsUpdate: accountsUpdate,

  quizzesList: quizzesList,
  quizzCreate: quizzCreate,
  quizzesUpdate: quizzesUpdate,

  formsList: formsList,
  formsUpdate: formsUpdate,
  formCreate: formCreate,

  answersList: answersList,
  answerAnalysis: answerAnalysis,

  fileUpload: fileUpload,
  fbImage: fbImage
}

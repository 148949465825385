<template>
  <aside class="v-components menu sidebar">
    <p class="menu-label">
      General
    </p>
    <ul class="menu-list">
      <li>
        <router-link class='m-links' to="/dashboard">Dashboard</router-link>
      </li>
    </ul>

    <p class="menu-label highlighted">
      Quizzes
    </p>
    <ul class="menu-list">
      <li>
        <router-link to="/quizzes" class='m-links'>Overview</router-link>
      </li>
    </ul>

    <p class="menu-label highlighted">
      Forms
    </p>
    <ul class="menu-list">
      <li>
        <router-link to="/forms" class='m-links'>Overview</router-link>
      </li>
    </ul>

    <p class="menu-label highlighted">
      Answers
    </p>
    <ul class="menu-list">
      <li>
        <router-link to="/answers" class='m-links'>Overview</router-link>
      </li>
    </ul>

    <p class="menu-label highlighted">
      Accounts
    </p>
    <ul class="menu-list">
      <li>
        <router-link to="/accounts" class='m-links'>Overview</router-link>

        <router-link :to="`/accounts/${userId}/update`" class='m-links'>{{ userName }}</router-link>
        <ul>
          <li>
            <router-link class='m-links' to="/logout">Logout</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </aside>
</template>

<style scoped>
  .menu {
    padding: 15px;
  }

  .m-links {
    color: #fff;
    transition: 0.3s all;
  }
</style>

<script>
export default {
  name: 'Sidebar',
  components: {
  },
  computed: {
    userId: function() {
      return this.$store.state.User.account._id
    },
    userName: function() {
      return this.$store.state.User.account.name || this.$store.state.User.account.email
    },
  }
}
</script>

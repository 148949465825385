import Vue from 'vue'

const date_later = {
  getMessage(field, args) {
    const [value, format] = args;

    return "The " + field + " must be 1 day later than " + Vue.prototype.$moment(value).format(format)
  },
  validate(value, [args]) {
    if (!value || !args || args == 'Invalid date') {
      return true;
    }

    const startDate = Vue.prototype.$moment(args);
    const endDate = Vue.prototype.$moment(value);

    const day = endDate.diff(startDate, 'days');

    if (day >= 1) {
      return true;
    }

    return false;
  },
};

const no_past = {
  getMessage(field) {
    return "The " + field + " cannot be past date"
  },
  validate(value) {
    const startDate = Vue.prototype.$moment();
    const endDate = Vue.prototype.$moment(value);

    const days = endDate.diff(startDate, 'days');

    if (days >= 0) {
      return true;
    }

    return false;
  },
};

const boolean = {
  getMessage(field) {
    return "The " + field + " must be boolean"
  },
  validate(value) {
    if (typeof value === "boolean") {
      return true;
    }

    return false;
  },
};

export default {
  extend: function(VeeValidate) {
    VeeValidate.extend('date_later', date_later);
    VeeValidate.extend('no_past', no_past);
    VeeValidate.extend('boolean', boolean);
  },
};
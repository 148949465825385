import Vue from 'vue'
import App from './App.vue'
import { sync } from 'vuex-router-sync'
import localStorage from 'vue-ls';
import router from './router'
import { default as VeeValidate, Validator } from 'vee-validate'
import isEmpty from 'lodash.isempty'
import draggable from 'vuedraggable'
import store from './store'
import Axios from 'axios'
import moment from 'moment-timezone'

import obj2fd from 'obj2fd'

import Buefy from 'buefy'
import '@/assets/scss/buefy-customize.scss'
import '@/assets/css/fonts.css'
import '@/assets/css/layouts.css'

import VeeValidateCustom from '@/misc/vee-v-custom.js'
import Api from '@/misc/api-requests.js'

Vue.prototype.$axios = Axios
Vue.prototype.$api = Api
Vue.prototype.$isEmpty = isEmpty
Vue.prototype.$moment = moment
Vue.prototype.$obj2FormData = obj2fd
Vue.prototype.$deepCopy = value => {
  return JSON.parse(JSON.stringify(value))
}

Vue.prototype.$moment.tz.setDefault("Asia/Rangoon")

Vue.use(localStorage, {
  namespace: 'app__',
  name: 'appStorage',
  storage: 'local',
})
sync(store, router)

Vue.use(Buefy)
Vue.use(VeeValidate)

Vue.component('draggable', {
  extends: draggable
})

Vue.config.productionTip = false
VeeValidateCustom.extend(Validator);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
